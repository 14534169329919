import React from 'react';
import LandingPage from "../templates/landing-page";
import BlogPostPage from "../templates/blog-post";
import PersonPage from "../templates/person";
import { graphql, useStaticQuery } from 'gatsby';

export const UnpublishedPage = (props) => {
    if (typeof window === "undefined") {
        return null;
    }
    console.log('Loaded UnpublishedPage');
    const rawPreviewData = window.__PRISMIC_PREVIEW_DATA__;
    const kind = rawPreviewData ? Object.keys(rawPreviewData)[0] : null;
    const previewData = rawPreviewData ? rawPreviewData[kind] : null;

    if (previewData) {
        switch (previewData.type) {
        case "landing_page":
            return <LandingPage location={props.location} pageContext={previewData.data} />;
        case "blog_post":
            const tagData = useStaticQuery(graphql`{
                allPrismicBlogCategory {
                    edges {
                        node {
                            data {
                                slug
                                name
                            }
                        }
                    }
                }
            }`);
            const topLevelBlogData = { tags: tagData.allPrismicBlogCategory.edges.map(({ node }) => node.data) };
            return <BlogPostPage location={props.location} pageContext={{ data: previewData.data, topLevelBlogData, suggestedPosts: [] }} />
        case "aboutuspersonpage":
            return <PersonPage pageContext={{data: previewData.data}} location={props.location}/>
        }    
    }
    return null;
}

export default UnpublishedPage